import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { DateTime, isBeforeNow } from '../date-helpers';
import { formatTime, formatHumanDate, formatMonth, formatYear, formatDayNumber } from '../date-format-helpers';
import getCalendarInvite from '../get-calendar-invite';
import { AVAILABLE_CALENDARS } from '../../constants';
const getCals = (entry, startAt, endAt) => {
  if (Boolean(startAt) && Boolean(endAt)) {
    return AVAILABLE_CALENDARS.reduce((acc, cal) => _objectSpread(_objectSpread({}, acc), {}, {
      [cal]: getCalendarInvite[cal](entry, startAt, endAt)
    }), {});
  }
  return null;
};
const validateOccurrences = function (occurrences) {
  let pastEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return occurrences.filter(occurrence => DateTime.fromISO(occurrence.startAt) < DateTime.fromISO(occurrence.endAt) && pastEvents ? isBeforeNow(occurrence.endAt) : !isBeforeNow(occurrence.endAt));
};
export const getOccurrences = (isSummary, entry, timeZone, inclCalendar) => {
  if (!entry) {
    return null;
  }
  const {
    startAt,
    endAt,
    hideAt,
    occurrences
  } = entry;
  const end = endAt || hideAt;
  if (!occurrences && !end && !startAt) {
    return null;
  }
  const normalisedOccurrences = occurrences && occurrences.length ? [...occurrences] : [{
    startAt,
    endAt: end
  }];
  const currentTimes = validateOccurrences(normalisedOccurrences);
  const pastTimes = validateOccurrences(normalisedOccurrences, true);
  const result = {
    summary: isSummary,
    times: currentTimes,
    timeZone
  };
  if (isSummary) {
    return result;
  } else {
    return _objectSpread(_objectSpread({}, result), {}, {
      pastTimes,
      times: currentTimes.map(x => _objectSpread(_objectSpread({}, x), {}, {
        calendar: inclCalendar && getCals(entry, x.startAt, x.endAt)
      }))
    });
  }
};
export const isExpired = (entry, timeZone) => {
  if (!entry) {
    return null;
  }
  const {
    hideAt
  } = entry;
  if (!hideAt) {
    return false;
  }
  return isBeforeNow(hideAt, timeZone);
};
export const firstOccurrence = (occurrences, timeZone) => occurrences.reduce((acc, cur) => {
  const dateTime = DateTime.fromISO(cur.startAt, {
    timeZone
  });
  return acc < dateTime ? acc : dateTime;
}, DateTime.fromISO(occurrences[0]['startAt'], {
  timeZone
}));
export const lastOccurrence = (occurrences, timeZone) => occurrences.reduce((acc, cur) => {
  const dateTime = DateTime.fromISO(cur.endAt, {
    timeZone
  });
  return acc > dateTime ? acc : dateTime;
}, DateTime.fromISO(occurrences[0]['endAt'], {
  timeZone
}));
export const summariseOccurrences = function (occurrences) {
  let timeZone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Zulu';
  const startDate = firstOccurrence(occurrences, timeZone);
  const endDate = lastOccurrence(occurrences, timeZone);
  // if it runs into two calendar years then we should the years as well
  const includeYear = endDate.year() - startDate.year() > 0;
  if (occurrences.length === 1 && startDate.dayOfYear() === endDate.dayOfYear()) {
    return "".concat(formatHumanDate(startDate, {
      includeMonth: true,
      includeYear
    }), ", ").concat(formatTime(startDate), " - ").concat(formatTime(endDate));
  } else if (startDate.dayOfYear() === endDate.dayOfYear()) {
    return "".concat(formatHumanDate(startDate, {
      includeMonth: true,
      includeYear
    }));
  } else {
    return includeYear ? "".concat(formatDayNumber(startDate), " ").concat(formatMonth(startDate), " ").concat(formatYear(startDate), " - ").concat(formatDayNumber(endDate), " ").concat(formatMonth(endDate), " ").concat(formatYear(endDate)) : "".concat(formatDayNumber(startDate), " ").concat(formatMonth(startDate), " - ").concat(formatDayNumber(endDate), " ").concat(formatMonth(endDate));
  }
};