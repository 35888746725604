/* eslint-disable prefer-template */
export const formatCalTime = time => time && new Date(time).toISOString().replace(/-|:|\.\d+/g, '');
// Using export const in order to utilize in tests

const iCal = function (entry, startTime, endTime) {
  let url = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  return encodeURI('data:text/calendar;charset=utf8,' + ['BEGIN:VCALENDAR', 'VERSION:2.0', 'BEGIN:VEVENT', 'URL:' + url, 'DTSTART:' + (formatCalTime(startTime) || ''), 'DTEND:' + (formatCalTime(endTime) || ''), 'SUMMARY:' + (entry.title || ''), 'DESCRIPTION:' + (entry.description || ''), 'LOCATION:' + (entry.address || ''), 'END:VEVENT', 'END:VCALENDAR'].join('\n'));
};
export default {
  iCal
};